import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Modal, ModalBody, } from 'reactstrap';
import { Col, Container, Dropdown, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap'
import homeIcon from '../../assets/images/logo.png'
import iconClose from '../../assets/images/close.png'
import LeaderboardList from '../../shared/components/LeaderBoard';
import ScheduleRaceList from '../../shared/components/RaceScheduled';
import InventoryList from '../../shared/components/InventoryData';
import io from 'socket.io-client';
import { RfoxIdProvider, useIsLoggedIn, useRfoxId, useUser } from 'rfox-id-react'
import { RfoxId } from 'rfox-id'
import { useLocation, useNavigate } from 'react-router-dom';
import GameCanvas from '../../shared/components/GameCanvas';
import { useQuery, useQueryClient } from 'react-query';
import { getSaleUrl, viewBannerData } from '../../query/banner/banner.query';
import { getBuyRaces } from '../../query/buyRaces/buyRaces.query';
import LoggedInView from '../../shared/components/LoggedInView';
import NotLoggedInView from '../../shared/components/NotLoggedInView';
import MiddleSideSection from '../../shared/components/MiddleSideSection';
import useReplayBtn from '../../shared/hooks/useReplayBtn';
import { playRandomSound, setVolume, stopSound } from '../../shared/components/util';
import useMediaQuery from '../../shared/hooks/useMediaQuery';
import bannerImage from '../../assets/images/banner-image.jpeg'
import { getCookie, removeCookie, setCookie } from '../../helper';
import { useWaxWallet } from "../../contexts/WaxWalletContext";



const sRootUrl = process.env.REACT_APP_API_ENDPOINT

const socket = io(sRootUrl, {
  transports: ['websocket', 'polling'],
});

const Home = () => {
  const query = useQueryClient()
  const location = useLocation();
  const isLoggedIn = useIsLoggedIn();

  const rfoxId = useRfoxId();

  const token = getCookie('token')
  const width = useMediaQuery('(max-width: 576px)')

  const [raceData, setRaceData] = useState([]);
  const [rfoxidReady, setRfoxidReady] = useState(false);
  const [selectedTab, setSelectedTab] = useState({ schedule: true, inventory: false });
  const [inventoryData, setInventoryData] = useState({});
  const [roundNFTs, setRoundNFTS] = useState([])
  const [userData, setUserData] = useState({});
  const [logginData, setLogginData] = useState(false);
  const [remainingNft, setRemainingNft] = useState(0);
  const [modal, setModal] = useState(false);
  const [raceTimer, setRaceTimer] = useState([]);
  const [isBuyRaces, setIsBuyRaces] = useState(false);
  const [raceRoundTeam, setRaceRoundTeam] = useState([]);
  const [msg, setMsg] = useState('');
  const [raceScheduleLoading, setRaceScheduleLoading] = useState(false)
  const [inventeryLoading, setInventeryLoading] = useState(false)
  const [isSoundEnabled, setIsSoundEnabled] = useState(false);
  const { replayBtnData, setReplayBtnData } = useReplayBtn()
  const [onBannerClick, setOnBannerClick] = useState(false);
  const [bannerImg, setBannerImg] = useState(bannerImage)
  const [gameStateData, setGameStateData] = useState({});
  const [collectionName, setCollectionName] = useState('');
  const [schemaName, setSchemaName] = useState('');
  const [isbtnClick, setisbtnClick] = useState(false);

  const {
    walletAddress,
    walletType,
    isConnected
  } = useWaxWallet();
  

  const rfoxID = new RfoxId({
    // rfoxIdUrl: "https://id-staging.rfoxvalt.com", //! DEV
    rfoxIdUrl: gameStateData?.sTestnetMainnet === 'testnet' ? "https://id-staging.rfoxvalt.com" : "https://id-dev.rfoxvalt.com", //! PROD
    // authCallbackUrl: "http://localhost:3000/", //! LOCAL
    authCallbackUrl: "https://memeracer-ui.rfox.com", //! PROD
    // authCallbackUrl: "http://meme-racer-react-panel.s3-website.ap-south-1.amazonaws.com/", //! STAG
  });

  //* AUDIO CONTROL BUTTON
  const toggleSound = () => {
    setIsSoundEnabled(prevIsSoundEnabled => { 
      return !prevIsSoundEnabled;
    });
  };

  useEffect(() => {
    rfoxID.init();
    setRfoxidReady(true);
  });

  // *GET BUY MORE RACERS
  const { isLoading, isFetching, refetch } = useQuery('buyRaces', getBuyRaces, {
    enabled: isBuyRaces,
    select: (data) => data.data.data,
    onSuccess: (response) => {
      // window.location.href = response.sMarketPlacePrefixUrl
      // navigate(response.sMarketPlacePrefixUrl)
      window.open(response, '_blank', 'noopener,noreferrer')
      setIsBuyRaces(false)
    }
  })

  // *GET BANNER IMAGE
  const { isLoading: bannerIsLoading, isFetching: bannerIsFetching } = useQuery('viewBanner', viewBannerData, {
    select: (data) => data.data.data,
    onSuccess: (response) => {
      setBannerImg(response?.sBannerImage)
    }
  })
  const toggle = () => setModal(!modal);
  const handleClose = () => {
    setModal(false)
    setMsg('')
  }

  
  useEffect(() => {
    // &  Listen: Request to initialize game
    socket.on("reqBeforeInitializeGame", {}, (error, data) => {
      if (error) {
        console.error('Error in reqBeforeInitializeGame:', error);
        return;
      }
      data?.reqBeforeInitializeGame && setRaceData((prevData) => {
        const shuffledData = data.reqBeforeInitializeGame.aRoundNFT;
        return shuffledData.map((item) => ({
          ...item,
          prevIndex: prevData.findIndex((prevItem) => prevItem.assetId === item.assetId),
        }));
      });
    });
    // &  Listen:  raceTimer raceSchedule to add timer 
    socket.on("raceTimer", (res, error) => {
      setRaceTimer(res.oData.data.aCurrentRoundsNFT)
    });
    // & Listen: race Round Team to get plane
    socket.on("raceRoundTeam", (data, error) => {
      console.log('%c raceRoundTeam', 'color: #408059',);
      setRaceRoundTeam(data);
      setisbtnClick(true)
    });
    // &  Listen:  playerSequence for leaderboard update
    socket.on("playerSequence", (data, error) => {
      setRaceData(data.data);
    });

    // &  Listen:  gameStates for tournament status ( used race sechdule )
    socket.on('gameStates', (res, error) => {
      setRemainingNft(res.gameState.nMaxNFTs - res.gameState.aTournamentLostNFT)
      setGameStateData(res.gameState)
      setCollectionName(res.gameState.sCollectionName)
      setSchemaName(res.gameState.sSchema)
      setRoundNFTS(res.aRounds);
      if (res.line === "initializeGame before gamePlay") {
        setRaceTimer(res.aRounds);
      }
      // if (res.line === 'beforeInitializeGame after gamePaly') {
      // }
    })
    // &  Listen:  raceRoundResult for round winners
    socket.on("raceRoundResult", (data, error) => {
      console.log('%c raceRoundResult', 'color: #994d75', data);
      setRaceData(data.data);
      stopSound();
      query.invalidateQueries('viewSocialLink')
      query.invalidateQueries('viewBanner')
      // setisbtnClick(true)
    });
    // ^ Listen:  connect_error
    socket?.on("connect_error", (error) => {
      console.log("Connection Error:", error);
    })
    // ^ Listen: disconnect
    socket.on("disconnect", (reason, details) => {
      if (reason === 'io server disconnect' || reason === 'io client disconnect') {
        socket.connect()

      }
    })
    // ~  Emit: Request to join board for refresh purpose
    socket.emit("reqJoinBoard", {}, (error, data) => {
      if (Object.keys(data.oData).length !== 0 && data.oData.sTournamentState !== "No game found") {
        setRemainingNft(data.oData.data.nMaxNFTs - data.oData.data.aTournamentLostNFT);
        setRoundNFTS(data.oData.aRounds);
        setRaceRoundTeam(data.oData.data.aRaceRoundTeam);
        setRaceTimer(data.oData.aRounds)
        setGameStateData(data.oData.data)
      }
    })
  }, [socket]);

  // * on Click of raceScheduled Tab
  const getRaceScheduleData = () => {
    socket.emit('reqRaceSchedule', {}, (error, data) => {
      // console.log('%c RaceScheduledData', 'color: #ffa640', data);
      setRaceTimer(data.oData.aRounds)
      setRaceScheduleLoading(false)
    })
  }
  // * Inventory Emit Function
  const getInventoryData = () => {
    const oData = { address: walletAddress? walletAddress : " ", page: 1, limit: 5000, collectionName: gameStateData.sCollectionName, schemaName: gameStateData.sSchema, testnet: gameStateData?.sTestnetMainnet === 'testnet' ? true : false }
   
    // const oData = {
    //   address: 'acbq4.c.wam', 
    //   page: 1,
    //   limit: 30,
    //   collectionName: 'rockettomoon', 
    //   schemaName: 'rocket', 
    //   testnet: false 
    // };
    
    console.log("oData is odata",oData)
    socket.emit("reqInventory", { ...oData }, (error, data) => {
      console.log('%c reqInventory', ' background-color:orange ; color: #d90000', data);
      if (data.reqInventory?.message === 'No matched NFT found in tournament') {
        setInventoryData({ message: 'NFT not found in tournament' })
        setInventeryLoading(false)
      }
      if (Array.isArray(data.reqInventory)) {
        setInventoryData(data)
        console.log("array is there in reqinventory");
        setInventeryLoading(false)
      }
    })
  }
  useEffect(() => {
    if (isbtnClick) {
      getInventoryData()
      setisbtnClick(false)
    }
  }, [isbtnClick])


  //* SOUND PLAY/ PAUSE
  useEffect(() => {
    if (!isSoundEnabled) {
      setVolume(0);
    }
    else {
      setVolume(1);
    }
    const handleGameStates = (res) => {
      if (res.line === "beforeInitializeGame after gamePaly") {
        stopSound();
      }
      if (res.line === "initializeGame before gamePlay") {
        if (isSoundEnabled) {
          playRandomSound();
        } else {
          stopSound();
        }
      }
    };
    socket.on('gameStates', handleGameStates);
    return () => {
      socket.off('gameStates', handleGameStates);
    };
  }, [isSoundEnabled]);

  const sendMessageToPhaser = (raceName, roundNo) => {
    setReplayBtnData({
      raceNo: raceName,
      roundNo: roundNo
    })

    if (raceName === 'Lucky Winner') {
      setMsg({ title: 'Lucky Winner', name: roundNo });
    }
    toggle();
  };
  // * send own nft data in phaser
  const sendMsgOwnNft = (data) => {
    const iframe = document.getElementById('phaser-iframe');
    if (iframe) {
      iframe.contentWindow.postMessage({ message: 'Hello Phaser!', data: data }, '*');
    }
  }

  //* LOGIN API
  useEffect(() => {
    if (location.search !== '') {
      console.log("location ", location.search);
      const result = rfoxId.auth.handleAuthCallback(location.search);
      console.log("result", result);
      setCookie('token', result.token, 1)

      socket.emit("reqJoinBoard", {}, (error, data) => {
        if (Object.keys(data.oData).length !== 0 && data.oData.sTournamentState !== "No game found") {
          fetch(sRootUrl + `api/v1/game/auth/login?sTestnetMainnet=${data.oData.data?.sTestnetMainnet || 'mainnet'}`, {
            method: "POST",
            headers: {
              "Authorization": `Bearer ${result.token}`,
            }
            ,
          }).then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          }).then(data => {
            setCookie('token', result.token, 1)
            setLogginData(true);
            console.log("user set data test : " ,data);
            setUserData(data);
          }).catch(error => {
            removeCookie('token')
            window.location.href = '/'
            console.log('There was a problem with the fetch operation:', error);
          });
        }
      })
    }
  }, []);


  const handleBuyMoreRacers = () => { setIsBuyRaces(true); refetch() }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      You can only access this after login
    </Tooltip>
  );

  const handleBannerClick = () => setOnBannerClick(true)

  const queryString = new URLSearchParams(replayBtnData).toString();

  const iframeUrl = `http://192.168.11.81:3530/editor/external/?sRoot=http://192.168.11.56:3005/&${queryString}`;
  return (
    <main className='main-sec'>
      <Container fluid>
        <Row>
          {width ? <>
            <div className='right-side-section'>
              <Row className='g-3'>
                <Col md={6} sm={12}>
                  <div className="last-meme-standing">
                    <img src={homeIcon} alt="" />
                    {/* <span>Last Meme Standing</span> */}
                  </div>
                </Col>
                <Col md={6} sm={12}>
                  <div className="price-pool-banner">
                    <img src={bannerImg} onClick={() => handleBannerClick()} alt="" />
                  </div>
                </Col>
              </Row>
            </div>
            <Col sm={12} className='left-side'>
              <div className='left-side-section'>
                <GameCanvas isSoundEnabled={isSoundEnabled} togglePlay={toggleSound} userData={userData} gameStateData={gameStateData} />
              </div>
            </Col>

            <Col sm={12} className="middle-side-section">
              <div className="leaderboard">
                <LeaderboardList raceData={raceData} />
              </div>
              <div className='tournament-status'>
                <div className='tournament-status-title'>tournament status</div>
                <div className='d-flex justify-content-between'>
                  <div className='status'>
                    <div>remaining</div>
                    <div className='status-value'>{remainingNft}</div>
                  </div>
                  <div className='status'>
                    <div>eliminated</div>
                    <div className='status-value'>{gameStateData?.aTournamentLostNFT ?? "0"}</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} className='right-side' style={{ paddingTop: '0' }}>
              <div className='right-side-section'>
                <div className='user-profile'>
                  <RfoxIdProvider rfoxId={rfoxID}>
                    <div className='App'>
                      {/* {isLoggedIn ? <>
                      <LoggedInView />
                    </> : <>
                      <NotLoggedInView />
                    </>} */}
                      {logginData ? <LoggedInView userData={userData} /> : <NotLoggedInView sendMsgOwnNft={sendMsgOwnNft} />}
                    </div>
                  </RfoxIdProvider>

                </div>
                <div className='schedule-inventory'>
                  {/* <button
                  className={`box ${selectedTab.schedule ? 'active' : ''}`}
                  onClick={() => setSelectedTab({ schedule: true })}
                >
                  Race Schedule
                </button>
                <button
                  className={`box ${selectedTab.inventory ? 'active' : ''}`}
                  onClick={() => setSelectedTab({ inventory: true })}
                >
                  Inventory
                </button> */}
                  {walletAddress ? (
                    <>
                      <button
                        className={`box ${selectedTab.schedule ? 'active' : ''}`}
                        onClick={() => { setSelectedTab({ schedule: true }); getRaceScheduleData(); setRaceScheduleLoading(true) }}
                      >
                        Race Schedule
                      </button>
                      <button
                        className={`box ${selectedTab.inventory ? 'active' : ''}`}
                        onClick={() => { setSelectedTab({ inventory: true }); getInventoryData(); setInventeryLoading(true) }}
                      >
                        Inventory
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className={`box ${selectedTab.schedule ? 'active' : ''}`}
                        onClick={() => setSelectedTab({ schedule: true })}
                      >
                        Race Schedule
                      </button>
                      <OverlayTrigger placement="top" overlay={renderTooltip}>
                        {({ ref, ...triggerHandler }) => (
                          <button
                            className="box"
                            ref={ref}
                            {...triggerHandler}
                          // style={{ pointerEvents: 'none' }}
                          >
                            Inventory
                          </button>
                        )}
                      </OverlayTrigger>
                    </>
                  )}
                </div>

                <div>
                  {selectedTab.schedule && (
                    <div className='schedule-list' >
                      <div className="active-racer">
                        <ScheduleRaceList roundNFTs={roundNFTs} sendMessageToPhaser={sendMessageToPhaser} raceTimer={raceTimer} raceScheduleLoading={raceScheduleLoading} />
                      </div>
                      <div>
                        <button className='buy-more-racers' onClick={() => handleBuyMoreRacers()}>Buy More Racers {(isLoading || isFetching) && <Spinner animation='border' size='sm' />}</button>
                      </div>

                    </div>
                  )}
                  {selectedTab.inventory && (<InventoryList sendMessageToPhaser={sendMessageToPhaser} userData={userData} inventoryData={inventoryData} raceRoundTeam={raceRoundTeam} gameStateData={gameStateData} roundNFTs={roundNFTs} raceTimer={raceTimer} inventeryLoading={inventeryLoading} />)}
                </div>
                <div>
                </div>
              </div>
            </Col>
          </> :
            <>
              <Col xl={5} lg={7} md={12} className='left-side'>
                <div className='left-side-section'>
                  <GameCanvas isSoundEnabled={isSoundEnabled} togglePlay={toggleSound} userData={userData} gameStateData={gameStateData} s />
                </div>
              </Col>

              <Col xl={3} lg={5} md={12} className='middle-side'>
                <MiddleSideSection onBannerClick={onBannerClick} setOnBannerClick={setOnBannerClick} bannerImg={bannerImg} handleBannerClick={handleBannerClick} remainingNft={remainingNft} gameStateData={gameStateData} raceData={raceData} raceRoundTeam={raceRoundTeam} />
              </Col>

              <Col xl={4} lg={12} md={12} className='right-side'>
                <div className='right-side-section'>
                  <div className='user-profile'>
                    <RfoxIdProvider rfoxId={rfoxID}>
                      <div className='App'>
                        {/* {isLoggedIn ? <>
                      <LoggedInView />
                    </> : <>
                      <NotLoggedInView />
                    </>} */}
                        {logginData ? <LoggedInView userData={userData} /> : <NotLoggedInView sendMsgOwnNft={sendMsgOwnNft} />}
                      </div>
                    </RfoxIdProvider>

                  </div>
                  <div className='schedule-inventory'>
                    {/* <button
                  className={`box ${selectedTab.schedule ? 'active' : ''}`}
                  onClick={() => setSelectedTab({ schedule: true })}
                >
                  Race Schedule
                </button>
                <button
                  className={`box ${selectedTab.inventory ? 'active' : ''}`}
                  onClick={() => setSelectedTab({ inventory: true })}
                >
                  Inventory
                </button> */}
                    {walletAddress ? (
                      <>
                        <button
                          className={`box ${selectedTab.schedule ? 'active' : ''}`}
                          onClick={() => { setSelectedTab({ schedule: true }); getRaceScheduleData(); setRaceScheduleLoading(true) }}
                        >
                          Race Schedule
                        </button>
                        <button
                          className={`box ${selectedTab.inventory ? 'active' : ''}`}
                          onClick={() => { setSelectedTab({ inventory: true }); getInventoryData(); setInventeryLoading(true) }}
                        >
                          Inventory
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className={`box ${selectedTab.schedule ? 'active' : ''}`}
                          onClick={() => setSelectedTab({ schedule: true })}
                        >
                          Race Schedule
                        </button>
                        <OverlayTrigger placement="top" overlay={renderTooltip}>
                          {({ ref, ...triggerHandler }) => (
                            <button
                              className="box"
                              ref={ref}
                              {...triggerHandler}
                            // style={{ pointerEvents: 'none' }}
                            >
                              Inventory
                            </button>
                          )}
                        </OverlayTrigger>
                      </>
                    )}
                  </div>
                  <Row className='g-3'>
                    <Col  md={12}sm={12}>
                      <div className="last-meme-standing">
                        <img src={homeIcon} alt="" />
                        {/* <span>Last Meme Standing</span> */}
                      </div>
                    </Col>
                    <Col md={12} sm={12}>
                      <div className="price-pool-banner">
                        <img src={bannerImg} onClick={() => handleBannerClick()} alt="" />
                      </div>
                    </Col>
                  </Row>
                  <div>
                    {selectedTab.schedule && (
                      <div className='schedule-list' >
                        <div className="active-racer">
                          <ScheduleRaceList roundNFTs={roundNFTs} sendMessageToPhaser={sendMessageToPhaser} raceTimer={raceTimer} raceScheduleLoading={raceScheduleLoading} />
                        </div>
                        <div>
                          <button className='buy-more-racers' onClick={() => handleBuyMoreRacers()}>Buy More Racers {(isLoading || isFetching) && <Spinner animation='border' size='sm' />}</button>
                        </div>

                      </div>
                    )}
                    {selectedTab.inventory && (<InventoryList sendMessageToPhaser={sendMessageToPhaser} userData={userData} inventoryData={inventoryData} raceRoundTeam={raceRoundTeam} gameStateData={gameStateData} roundNFTs={roundNFTs} raceTimer={raceTimer} inventeryLoading={inventeryLoading} />)}
                  </div>
                  <div>
                  </div>
                </div>
              </Col>
            </>
          }
        </Row>
      </Container>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <button className='btnModalClose'><img src={iconClose} alt="" onClick={() => handleClose()} /></button>
          <div className='inner-content'>
            {msg !== '' ? <>
              <div className='luckywinner-sec'>
                <h1>{msg.title}s</h1>
                <ul className='lucky-winner-list'>
                  {msg?.name[0]?.map((winner, i) => {
                    return (
                      <Fragment key={i}>
                        <li>{winner?.sNFTName}</li>
                      </Fragment>
                    )
                  })}
                </ul>
              </div></> :
              <>
                <iframe id="phaser-iframe1" title="Meme Racer Game Replay" src={`${process.env.REACT_APP_REPLY_ENDPOINT}?sRoot=${sRootUrl}&raceNo=${replayBtnData.raceNo}&roundNo=${replayBtnData.roundNo}`}></iframe>
                {/* <iframe id="phaser-iframe1" src={iframeUrl} ></iframe> */}
              </>
            }
          </div>
        </ModalBody>
      </Modal>
    </main>
  )
}

export default Home
