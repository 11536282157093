import React, { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { formatTime } from "../../../shared/constant";

import { useWaxWallet } from "../../../contexts/WaxWalletContext";
const InventoryList = ({ sendMessageToPhaser, userData, inventoryData, raceRoundTeam, gameStateData,inventeryLoading }) => {
  const [remainingTimes, setRemainingTimes] = useState([]);
  console.log('%c InventoryData',' background-color : yellow ; color: #9c66cc', inventoryData);

  
  const {aRoundNFT , aAllLostNFT} = gameStateData;
  const tableContainerRef = useRef(null);
  useEffect(() => {
    const nStartTimes = (Object.keys(inventoryData).length > 0) && inventoryData?.reqInventory?.map((inventery) => inventery.timer);
    (nStartTimes && nStartTimes.length > 0) && setRemainingTimes(nStartTimes)
  }, [inventoryData]);
  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTimes((prevTimes) => {
        return prevTimes.map((time) => {
          if (time <= 0) {
            return 0;
          }
          return time - 1000;
        });
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [inventoryData]);
  useEffect(() => {
    const playingRowIndex = inventoryData?.reqInventory?.findIndex(
      (round) => round?.sNFTState === 'Playing'
    );
    if (playingRowIndex !== -1 && tableContainerRef.current) {
      const rows = tableContainerRef.current.querySelectorAll('tbody tr');
      if (rows[playingRowIndex]) {
        rows[playingRowIndex].scrollIntoView({ behavior: 'smooth',
          block: 'center',
          inline: 'nearest', });
      }
    }
  }, [inventoryData]);

  const handleReplayBtn = (nRaceNumber, nRoundNumber) => {
    sendMessageToPhaser(nRaceNumber, nRoundNumber)
  }
  const handleSalesUrl = (url) => {
    // window.location.href = url;
    window.open(url, '_blank', 'noopener,noreferrer')
  };
  console.log("user data is ",userData);
  const {
    walletAddress,
    walletType,
    isConnected,
    loginWithWax,
    loginWithAnchor,
    logout,
  } = useWaxWallet();

  return (
    <div className='inventory-list'>
      <div className="inventory-title">WALLET ADDRESS : {walletAddress.length !== 0 ? walletAddress : " "} </div>
      <div className="active-race-details">
        <div className="activeRace-title">{Array.isArray(inventoryData?.reqInventory) ? inventoryData?.reqInventory?.length : '0'} ACTIVE RACERS</div>
        <div className="active-race-table" ref={tableContainerRef}>
          <Table>
            <thead>
              <tr>
                <th>NAME</th>
                <th>POSITION</th>
                <th></th>
                <th>RACE START</th>
              </tr>
            </thead>
            <tbody>
              {!inventoryData.message ?
                (!inventeryLoading) ? inventoryData?.reqInventory?.map((inventery, index) => {
                  const remainingTime = remainingTimes[index] || 0;
                  const isLive = raceRoundTeam?.data?.some((item) => item.sNFTName === inventery.sNFTName)
                  const position = aRoundNFT?.filter((item) => item.sNFTName === inventery.sNFTName)
                  return (
                    <tr key={index}>
                      <td>{inventery.sNFTName}</td>
                      <td>{position ? position?.map(item => item?.indexLeaderBoard+1 || '-') : '-'}</td>
                      <td> <button value={inventery.assetId} onClick={() => handleSalesUrl(inventery?.sURL)}>Sell</button></td>
                      <td>
                        {isLive ? ('LIVE') : (
                          <>
                            {inventery.sNFTState === 'NextRound' ? (
                              <button className="reply-btn" onClick={() => sendMessageToPhaser(inventery.nRaceNumber, inventery.nRoundNumber)}>
                                Replay
                              </button>
                            ) : inventery.sNFTState === 'Scheduled' ? (
                              <span>{formatTime(remainingTime)}</span>
                            ) : null}
                          </>
                        )}
                      </td>
                    </tr>
                  )
                }) : <tr> <td colSpan={4}>Loading...</td> </tr>
                : <tr> <td colSpan={4}>{inventoryData.message}</td> </tr>
              }
            </tbody>
          </Table>
        </div>
      </div>
      <div className="Eliminate-race-details">
        <div className="Eliminate-race-title">{Array.isArray(inventoryData?.eliminatedNFTs) ? inventoryData?.eliminatedNFTs?.length : '0'} Eliminated</div>
      </div>
      <div className="eliminate-race-table">
        <Table>
          <thead>
            <tr className="elim-header" >
              <th>NAME</th>
              <th>POSITION</th>
              <th>RACE START</th>
            </tr>
          </thead>
          <tbody>
            {!inventoryData.message ?
              (!inventeryLoading) ? inventoryData?.eliminatedNFTs?.map((inventery, index) => {
                const position = aAllLostNFT?.filter((item) => item.sNFTName === inventery.sNFTName)
                return position?.length > 0 ? <>
                  {position?.map(item => {
                    return (
                      <tr key={index}>
                        <td>{inventery?.sNFTName}</td>
                        <td>{item?.indexLeaderBoard + 1 || '-'}</td>
                        <td>
                          <button className="reply-btn" onClick={() => handleReplayBtn(inventery.nRaceNumber, inventery.nRoundNumber)}>
                            Replay
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </> : ''
              }) : <tr> <td colSpan={3}>Loading...</td> </tr>
              : <tr> <td colSpan={3}>NFT data not found</td> </tr>
            }
          </tbody>
        </Table>
      </div>
    </div >
  )
}

export default InventoryList;