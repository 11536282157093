import React, { useEffect, useState } from 'react';

const LeaderboardList = ({ raceData }) => {
  const [newData, setNewData] = useState(raceData);
  
  useEffect(() => {
    if (raceData?.length > 0) {
      setTimeout(() => {
        setNewData((prevData) => {
          if (raceData === undefined) {
            return prevData;
          } else {
            return raceData;
          }
        });
      }, 1000)
    }
  }, [raceData])


  return (
    <>
      <div className="leaderboard-title">Leaderboard</div>
      <div className='leaderboard-details-sec'>
        <ul className="leaderboard-list">
          {newData && newData.map((item, indexLeaderBoard) => {
            return (
              <li key={item.assetId} className="leaderboard-item" style={{ top: `${indexLeaderBoard * 30}px` }}>
                <div className="leaderboard-rank">
                  <span className='rank-circle' style={{ background: item.colorCode }}></span>
                  <span>{indexLeaderBoard + 1}</span>
                </div>
                <div className="leaderboard-name" style={{ color: item.colorCode }}>{item.sNFTName}</div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default LeaderboardList;
