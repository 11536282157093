/* eslint-disable no-mixed-operators */
import axios from 'axios'
import { navigationTo, removeToken } from './helper'

const instance = axios.create({
  // baseURL: 'http://192.168.11.56:3005/api/v1/game'
  baseURL: process.env.REACT_APP_API_ENDPOINT + 'api/v1/game'

})

// instance.interceptors.request.use(
//   (req) => {
//     const token = localStorage.getItem('token') || sessionStorage.getItem('token')
//     if (!req.headers.Authorization && token) {
//       req.headers.Authorization = token
//       return req
//     }
//     return req
//   },
//   (err) => {
//     return Promise.reject(err)
//   }
// )

instance.interceptors.response.use(
  (res) => {
    return res
  },
  (err) => {
    if ((err?.response && err?.response?.status === 404 || err?.response?.status === 419 || err?.response?.status === 401)) {
      // removeToken()
      navigationTo({ to: '/login', replace: true })
      return Promise.reject(err)
    }
    return Promise.reject(err)
  }
)

export default instance
