import { Dropdown } from "react-bootstrap";
import { useRfoxId } from "rfox-id-react";
import { removeCookie } from "../../../helper";

const LoggedInView = (userData) => {
    // console.log('userData :>> ', userData);
    const rfoxId = useRfoxId();

    // const user = useUser();
    // console.log('user :>> ', user);

    // if (!user) return null;

    // const { id, name, role, email } = user;

    let srcImg = userData.userData.data.photoUrl
    //* Logout Button
    const handleLogout = () => {
        removeCookie('token')
        // Get the current URL
        const url = window.location.href
        // Split the URL at "?token" and take the first part
        const modifiedUrl = url.split('?token')[0];
        window.location.href = modifiedUrl
    }
    return (
        <>
            <div className='profile'>
                <Dropdown>
                    <Dropdown.Toggle>
                        <div className='user-name me-2'>
                            <img src={srcImg} alt="" />
                            <div className='profile-name'>{userData.userData.data.name}</div>
                        </div>
                        <div className='img'>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='up-arrow'>
                        <Dropdown.Item onClick={() => handleLogout()}>
                            <i className='icon-logout'></i>
                            Logout
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>

    );
};

export default LoggedInView;