const soundFiles = [
    'sound/sound1.mp3',
    'sound/sound2.mp3',
    'sound/sound3.mp3',
    'sound/sound4.mp3',
    'sound/sound5.mp3',
    'sound/sound6.mp3',
];

let currentAudio = null;

export function playRandomSound() {
    if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
    }

    const randomIndex = Math.floor(Math.random() * soundFiles.length);
    currentAudio = new Audio(soundFiles[randomIndex]);
    currentAudio.volume = 1;
    currentAudio.play();
    return currentAudio.src;
}

export function setVolume(volume) {
    if (currentAudio) {
        currentAudio.volume = volume;
    }
}

export function stopSound() {
    if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
        currentAudio = null;
    }
}