import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'bootstrap/scss/bootstrap.scss'
import './assets/sass/main.scss'
// import 'react-datepicker/dist/react-datepicker.css'
// import 'react-datetime/css/react-datetime.css'
// import 'react-loading-skeleton/dist/skeleton.css'
// import 'react-toastify/dist/ReactToastify.css'
import './assets/sass/_variables.scss'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
