import { AuthProvider } from "rfox-id";
import { useEffect, useState } from "react";
import { useRfoxId } from "rfox-id-react";
// import loginIcon from '../../../assets/images/login-icon.png'
import loginIcon from "../../../assets/images/login-icon.png";
import { useWaxWallet } from "../../../contexts/WaxWalletContext";
import LoginModalView from "../LoginModalView/index";

const NotLoggedInView = ({sendMsgOwnNft}) => {
    // const rfoxId = useRfoxId();
    // const loginWithRFOXId = (provider) => {
        
    //     // launch popup for user to login
    //     const redirectUrl = rfoxId.auth.getLoginUr(provider);
    //     window.location.href = redirectUrl;
    // };
    // return (
    //     <>
    //         <div className="login" onClick={() => loginWithRFOXId(AuthProvider.GOOGLE) }>
    //             <img src={loginIcon} alt="" />
    //             <div>Login </div>
    //         </div>
    //     </>
    // )
    const { walletAddress, isConnected, logout } = useWaxWallet();
    const [openLogin, setOpenLogin] = useState(false);


    useEffect(() => {
      if (isConnected && walletAddress) {
        setOpenLogin(false);
      }
    }, [isConnected, walletAddress]);
    
      return (
        <>
          <div
            className="login"
            // onClick={() => loginWithRFOXId(AuthProvider.GOOGLE)}
            onClick={isConnected ? () => logout() : () => setOpenLogin(true)}
          >
            {isConnected && walletAddress ? (
          <>{walletAddress}</>
        ) : (
          <>
            <img src={loginIcon} alt="" />
            <div>Login</div>
          </>
        )}
          </div>
    
          {/* <div
            className="login"
            onClick={isConnected ? () => logout() : () => loginWithWax()}
          >
            <img src={loginIcon} alt="" />
            <div>
              {isConnected && walletType === "wax" ? walletAddress : "Wax Login"}
            </div>
          </div>
    
          <div
            className="login"
            onClick={isConnected ? () => logout() : () => loginWithAnchor()}
          >
            <img src={loginIcon} alt="" />
            <div>
              {isConnected && walletType === "anchor"
                ? walletAddress
                : "Anchor Login"}
            </div>
          </div>
        </>
      );
    }; */}
  <LoginModalView isOpen={openLogin} onClose={() => setOpenLogin(false)} />
    </>
  );
};
    
    
    export default NotLoggedInView;