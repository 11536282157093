import React, { Fragment, useEffect, useRef, useState } from "react";
import { Table, Dropdown, Form } from "react-bootstrap";
import { FormGroup } from "reactstrap";

const ScheduleRaceList = ({ roundNFTs, sendMessageToPhaser, raceTimer, raceScheduleLoading }) => {
  const [remainingTimes, setRemainingTimes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const tableContainerRef = useRef(null);

  useEffect(() => {
    const nStartTimes = raceTimer?.map((round) => round.nRaceTimer);
    if (nStartTimes && nStartTimes.length > 0) {
      setRemainingTimes(nStartTimes);
    }
  }, [raceTimer]);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTimes((prevTimes) => {
        return prevTimes.map((time) => {
          if (time <= 0) {
            return 0;
          }
          return time - 1000;
        });
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [roundNFTs]);

  useEffect(() => {
    const playingRowIndex = roundNFTs?.findIndex(
      (round) => round?.sRaceStatus === 'Playing'
    );
    if (playingRowIndex !== -1 && tableContainerRef.current) {
      const rows = tableContainerRef.current.querySelectorAll('tbody tr');
      if (rows[playingRowIndex]) {
        rows[playingRowIndex].scrollIntoView({  behavior: 'smooth',
          block: 'center',
          inline: 'nearest', });
      }
    }
  }, [roundNFTs]);

  function formatTime(time) {
    if (time === null) {
      return '00:00:00';
    }
    if (time === 0) {
      return '00:00:00';
    }
    let seconds = Math.floor((time / 1000) % 60);
    let minutes = Math.floor((time / 1000 / 60) % 60);
    let hours = Math.floor((time / 1000 / 60 / 60) % 24);
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
  }

  function padZero(number) {
    return number.toString().padStart(2, '0');
  }

  // Filter rounds based on search term
  const filteredRounds = roundNFTs?.filter((round) =>
    round?.aNFT?.some((item) =>
      item.some((name) =>
        name.sNFTName.toLowerCase().includes(searchTerm.toLowerCase())
      )
    )
  );

  const handleBuyNow = (url) => {
    // window.location.href = url;
    window.open(url, '_blank', 'noopener,noreferrer')
  };
  const handleReplayBtn = (nRaceNumber , nRoundNumber) => {
    sendMessageToPhaser(nRaceNumber , nRoundNumber)
  }
  return (
    <>
      <div className='search-schedule text-light'>
        <FormGroup className="form-group">
          <Form.Control
            type="text"
            placeholder="( Search NFT Name )"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
        </FormGroup>
      </div>
      <div className='schedule-race' ref={tableContainerRef} style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <Table responsive borderless>
          <thead>
            <tr>
              <th className="bg-transparent">Race Id</th>
              <th className="bg-transparent">Eliminated</th>
              <th className="bg-transparent">RACERS</th>
              <th className="bg-transparent">Start Time</th>
            </tr>
          </thead>
          <tbody>
            {raceScheduleLoading ? <tr><td colSpan={4} className="text-center">Loading...</td></tr> : filteredRounds?.map((round, index) => {
              const remainingTime = remainingTimes[index] || 0;
              let roundNo = round.sRaceName.split("r")[1];
              let raceNo = round.sRaceName.split("r")[2];
              return (
                <tr key={index}>
                  <td>{round.sRaceName}</td>
                  <td>{round?.oLostNFT ? round.oLostNFT.sNFTName : '-'} </td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        View List
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {round?.aNFT?.map((item, i) => (
                          <React.Fragment key={i}>
                            {item?.map((name) => (
                              <Dropdown.Item
                                key={name?.sNFTName}
                                onClick={(e) => e.preventDefault()}
                              >
                                <span value={item.assetId}>{name?.sNFTName}</span>
                                <button value={item.assetId} onClick={() => handleBuyNow(name?.sURL)}>Buy Now</button>
                              </Dropdown.Item>
                            ))}
                          </React.Fragment>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>
                    {round?.sRaceStatus === 'Playing' ? 'LIVE'
                      : (round?.isLuckyRound && round.sRaceStatus === 'Scheduled') ? 'Lucky Winner' :
                        (round?.isLuckyRound && round.sRaceStatus === 'Finished') ? <button onClick={() => sendMessageToPhaser('Lucky Winner', round.aNFT)} className="replay-btn" > Replay </button>
                          : round.sRaceStatus === 'Scheduled' ? (<span>{formatTime(remainingTime)}</span>)
                            : round.sRaceStatus === 'Finished' &&
                            (<button onClick={() => handleReplayBtn(raceNo,roundNo)} className="replay-btn" > Replay </button>)
                    }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ScheduleRaceList;
