// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: #8901d8;
  --black-color: #000;
  --white-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/assets/sass/_variables.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;EACA,mBAAA;EACA,mBAAA;AACJ","sourcesContent":[":root {\n    --primary-color: #8901d8;\n    --black-color: #000;\n    --white-color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
