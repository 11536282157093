import loginIcon from "../../../assets/images/login-icon.png";
import { useWaxWallet } from "../../../contexts/WaxWalletContext";

function LoginModalView({ isOpen, onClose }) {
  const { loginWithWax, loginWithAnchor } = useWaxWallet();

  if (!isOpen) return null;

  return (
    <div className="loginModal">
      <div className="modalOverlay">
        <div className="modalContent">
          <div className="loginButton" onClick={() => loginWithWax()}>
            <img src={loginIcon} alt="" />
            <div>Wax Login</div>
          </div>
          <div className="loginButton" onClick={() => loginWithAnchor()}>
            <img src={loginIcon} alt="" />
            <div>Anchor Login</div>
          </div>
          <button className="closeButton" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default LoginModalView;