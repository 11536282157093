// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  margin: 0px;
  padding: 0px;
}

body {
  font-size: 14px;
  transition: none;
}

img {
  border: 0px;
  max-width: 100%;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0
}

a {
  text-decoration: none;
  outline: none;
  display: inline-block;
}

a:focus,
a:active,
a:visited {
  outline: none;
  border: 0px;
}

a:hover {
  text-decoration: none;
}

select:focus {
  outline: none;
}

input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

textarea {
  resize: none;
}

.main-background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

  img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;;EAKE,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;;EAEE,gBAAgB;EAChB,SAAS;EACT;AACF;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,qBAAqB;AACvB;;AAEA;;;EAGE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;;EAEX;IACE,YAAY;IACZ,aAAa;IACb,iBAAiB;EACnB;AACF","sourcesContent":["*,\n*:before,\n*:after {\n  -webkit-box-sizing: border-box;\n  -moz-box-sizing: border-box;\n  box-sizing: border-box;\n}\n\n* {\n  margin: 0px;\n  padding: 0px;\n}\n\nbody {\n  font-size: 14px;\n  transition: none;\n}\n\nimg {\n  border: 0px;\n  max-width: 100%;\n}\n\nul,\nol {\n  list-style: none;\n  margin: 0;\n  padding: 0\n}\n\na {\n  text-decoration: none;\n  outline: none;\n  display: inline-block;\n}\n\na:focus,\na:active,\na:visited {\n  outline: none;\n  border: 0px;\n}\n\na:hover {\n  text-decoration: none;\n}\n\nselect:focus {\n  outline: none;\n}\n\ninput:focus {\n  outline: none;\n}\n\ntextarea:focus {\n  outline: none;\n}\n\ntextarea {\n  resize: none;\n}\n\n.main-background {\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: -1;\n\n  img {\n    width: 100vw;\n    height: 100vh;\n    object-fit: cover;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
