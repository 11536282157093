export function formatTime(time) {
    if (time === null) {
        return '00:00:00';
    }
    if (time === 0) {
        return '00:00:00';
    }
    let seconds = Math.floor((time / 1000) % 60);
    let minutes = Math.floor((time / 1000 / 60) % 60);
    let hours = Math.floor((time / 1000 / 60 / 60) % 24);
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
}

export function padZero(number) {
    return number.toString().padStart(2, '0');
}
