import React, { useState } from 'react'
import homeIcon from '../../../assets/images/logo.png'
import { useQuery } from 'react-query'
import { getSaleUrl, viewBannerData } from '../../../query/banner/banner.query'
import LeaderboardList from '../LeaderBoard'

const MiddleSideSection = ({ remainingNft, gameStateData,raceData ,raceRoundTeam , onBannerClick, setOnBannerClick, bannerImg, handleBannerClick}) => {
  // *GET sale URl
  useQuery('saleUrl', getSaleUrl, {
    enabled: onBannerClick,
    select: (data) => data.data.data,
    onSuccess: (response) => {
      // window.location.href = response.sSaleUrl;
      window.open(response.sSaleUrl, '_blank', 'noopener,noreferrer');
      setOnBannerClick(false);
    }
  })

  return (
    <>
      <div className="middle-side-section">
        <div className="last-meme-standing">
          <img src={homeIcon} alt="" />
        </div>
        <div className="price-pool-banner">
          <img src={bannerImg} onClick={() => handleBannerClick()} alt="" />
        </div>

        <div className='tournament-status'>
          <div className='tournament-status-title'>tournament status</div>
          <div className='d-flex justify-content-between'>
            <div className='status'>
              <div>remaining</div>
              <div className='status-value'>{remainingNft}</div>
            </div>
            <div className='status'>
              <div>eliminated</div>
              <div className='status-value'>{gameStateData?.aTournamentLostNFT ?? "0"}</div>
            </div>
          </div>
        </div>

        <div className="leaderboard">
          <LeaderboardList raceData={raceData} />
        </div>
      </div>
    </>
  )
}

export default MiddleSideSection
