import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '../views/Home'

const AllRoutes = () => {
  return (
    <Routes>
      <Route path='/'>
        <Route
          index
          element={<Home />}
        />
      </Route>
    </Routes>
  )
}

export default AllRoutes
