import * as waxjs from "@waxio/waxjs/dist";
import AnchorLink from "anchor-link";
import AnchorLinkBrowserTransport from "anchor-link-browser-transport";
import { Api } from "eosjs";
const { createContext, useContext, useState, useEffect } = require("react");

const WaxWalletContext = createContext({
  waxJs: undefined,
  anchor: undefined,
  rpc: undefined,
  api: undefined,
  loginWithWax: async () => {},
  loginWithAnchor: async () => {},
  logout: async () => {},
  isConnecting: false,
  isConnected: false,
  walletAddress: "",
  walletType: "",
  error: undefined,
});

export const WaxWalletProvider = ({ nodeUrl, chainId, children }) => {
  const [waxJS, setWaxJS] = useState(undefined);
  const [anchor, setAnchor] = useState(undefined);
  const [api, setApi] = useState(undefined);
  const [rpc, setRpc] = useState(undefined);
  const [error, setError] = useState(undefined);

  const [isConnecting, setIsConnecting] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const loginWithWax = async () => {
    if (!nodeUrl || !chainId) {
      throw new Error("nodeUrl and chainId are required");
    }

    setIsConnecting(true);

    try {
      const wax = new waxjs.WaxJS({
        rpcEndpoint: nodeUrl,
        chainId: chainId,
        tryAutoLogin: true,
      });

      await wax.login();

      setWaxJS(wax);
      setRpc(wax.rpc);
      setApi(
        new Api({
          rpc: wax.rpc,
          signatureProvider: wax.api.signatureProvider,
        })
      );
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setIsConnecting(false);
    }
  };

  const loginWithAnchor = async () => {
    if (!nodeUrl || !chainId) {
      throw new Error("nodeUrl and chainId are required");
    }

    setIsConnecting(false);

    try {
      const transport = new AnchorLinkBrowserTransport();
      const link = new AnchorLink({
        transport,
        chains: [
          {
            nodeUrl: nodeUrl,
            chainId: chainId,
          },
        ],
      });

      const identify = await link.login("anchor wallet");
      const { session } = identify;

      setAnchor(session);
      setRpc(session.rpc);
      setApi(
        new Api({
          rpc: session.rpc,
          signatureProvider: session.makeSignatureProvider(),
        })
      );
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setIsConnecting(false);
    }
  };

  const tryLoginWithWax = async () => {
    if (!nodeUrl || !chainId) {
      throw new Error("nodeUrl and chainId are required");
    }

    setIsConnecting(true);

    try {
      const wax = new waxjs.WaxJS({
        rpcEndpoint: nodeUrl,
        chainId: chainId,
        tryAutoLogin: true,
      });

      const isAutoLoginAvailable = await wax.isAutoLoginAvailable();

      if (isAutoLoginAvailable) {
        await wax.login();
        setWaxJS(wax);
        setRpc(wax.rpc);
        setApi(
          new Api({
            rpc: wax.rpc,
            signatureProvider: wax.api.signatureProvider,
          })
        );
      }

      return !!wax;
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setIsConnecting(false);
    }

    return false;
  };

  const tryLoginWithAnchor = async () => {
    if (!nodeUrl || !chainId) {
      throw new Error("nodeUrl and chainId are required");
    }

    setIsConnecting(true);

    try {
      const transport = new AnchorLinkBrowserTransport();
      const link = new AnchorLink({
        transport,
        chains: [
          {
            nodeUrl: nodeUrl,
            chainId: chainId,
          },
        ],
      });

      const session = await link.restoreSession("anchor wallet");

      if (session) {
        setAnchor(session);
        setRpc(session.rpc);
        setApi(
          new Api({
            rpc: session.rpc,
            signatureProvider: session.makeSignatureProvider(),
          })
        );
      }

      return !!session;
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setIsConnecting(false);
    }

    return false;
  };

  const logout = async () => {
    await waxJS?.logout();
    // await anchor?.logout();
    await anchor?.remove();

    setWaxJS(undefined);
    setAnchor(undefined);
    setRpc(undefined);
    setApi(undefined);
  };

  useEffect(() => {
    const tryLogin = async () => {
      const loggedIn = await tryLoginWithAnchor();
      if (!loggedIn) {
        await tryLoginWithWax();
      }
    };

    if (isFirstLoad) {
      tryLogin();
      setIsFirstLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstLoad]);

  return (
    <WaxWalletContext.Provider
      value={{
        waxJS,
        anchor,
        rpc,
        api,
        loginWithWax,
        loginWithAnchor,
        logout,
        isConnecting,
        isConnected: !!waxJS || !!anchor,
        walletAddress: waxJS?.userAccount ?? anchor?.auth?.actor?.toString(),
        walletType: waxJS ? "wax" : anchor ? "anchor" : "",
        error,
      }}
    >
      {children}
    </WaxWalletContext.Provider>
  );
};

export const useWaxWallet = () => {
  const context = useContext(WaxWalletContext);
  if (context === undefined) {
    throw new Error("useWaxWallet must be used within a WaxWalletProvider");
  }
  return context;
};